import * as React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/layout"
import SEO from "../components/seo"
import useUser from '../model/User'
import AuthComponent from '../components/auth'

const LoginPage = () => {
  const user = useUser();
  return (
    <Layout>
      <AuthComponent/>
    </Layout>
  )
  }

export default LoginPage
